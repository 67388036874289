.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.rightSideBar {
  width: 0%;
  background-color: white;
  top: 0;
  right: 0;
  z-index: 99;
  transition: 0.1s;
}
.rightSideBar.activez {
  width: 100%;
}

.loading .ant-spin {
  color: #3e79f7;
}
#reload_href {
  color: white !important;
  float: right !important;
  margin-top: -30px;
}
.InfoCircleOutlined_csp1 {
  font-size: 18px;
  font-weight: bold;
}
.InfoCircleOutlined_span_csp1 {
  position: absolute;
  right: -24px;
  bottom: 8px;
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.height_70px_csp3 {
  height: 70px !important;
}
.background_csp4 {
  background: #1245a8 !important;
}

.background_csp5 {
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;
}
.float-right {
  float: "right";
}
.marginTop_minus10px_csp6 {
  margin-top: -10px;
}

#remarkTable .ant-empty.ant-empty-normal {
  min-height: 250px !important;
}

.uploadWrapper .ant-upload-list-item-name {
  color: blue;
  cursor: pointer;
}

.ant-divider-horizontal.ant-divider-with-text::after,
.ant-divider-horizontal.ant-divider-with-text::before {
  top: 0 !important;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: none;
}
.pg-viewer-wrapper {
  overflow: hidden !important;
}
